import { PaymentDaysContext, PaymentDaysContextValue } from "contexts/PaymentDaysContext";
import { GetStaticPaths, GetStaticProps } from "next";
import { useRouter } from "next/router";

import { fetchFaqPageBySlug, fetchPageBySlug } from "@clients/contentfulClient";
import Meta from "@components/Meta/meta";
import BrandPortalLogin from "@containers/BrandPortalLogin";
import FaqPage from "@containers/FaqPage";
import Layout from "@containers/Layout/layout";
import { Event, trackEvent } from "@utils/events";
import { metaImageUrl, socialMediaDefaultImage } from "@utils/imageHelpers/imageHelper";
import {
  getInsuranceCoveredTargetGroups,
  getInsuranceName,
} from "@utils/layoutHelpers/layoutHelper";
import { getStaticPropsInfo } from "@utils/pageHelpers/getStaticPropsInfo";
import { pathHasContent } from "@utils/pageHelpers/pathHasContent";
import { getBaseUrl } from "@utils/urlResolver";

import useAuth from "hooks/useAuth";
import { getFaqUrlStructure } from "services/FaqStructureService";
import { GetSiteTreeStructure } from "services/SiteTreeStructureFactory";
import { UrlStructureService, getUrlStructureItems } from "services/UrlStructureService";
import { getLayoutData } from "services/layoutService";

import EventPage from "../containers/EventPage";
import InsuranceClaimPage from "../containers/InsuranceClaimPage";
import InsurancePage from "../containers/InsurancePage";
import LandingPage from "../containers/Landingpage";
import NewsPage from "../containers/NewsPage";
import ReportPage from "../containers/ReportPage";
import StandardPage from "../containers/StandardPage";

const PATHS_WITH_AUTH = ["/testsidor", "/test-nya-forebygga", "/varumarke"];

type PageProps = {
  pageType: string;
  pageData: any;
  slug: string;
  leftMenuItems: any;
};

function Page({ pageType, pageData, slug, leftMenuItems }: PageProps) {
  switch (pageType) {
    case "landingpage":
      return <LandingPage pageData={pageData} />;
    case "standardpage":
      return <StandardPage pageData={pageData} slug={slug} leftMenuItems={leftMenuItems} />;
    case "insurancepage":
      return <InsurancePage pageData={pageData} />;
    case "insuranceclaimpage":
      return <InsuranceClaimPage pageData={pageData} />;
    case "eventpage":
      return <EventPage pageData={pageData} />;
    case "newspage":
      return <NewsPage pageData={pageData} />;
    case "reportpage":
      return <ReportPage pageData={pageData} />;
    case "faq":
      return <FaqPage pageData={pageData} />;
    default:
      console.error(`Component not found for page type ${pageType}`);
      return null;
  }
}

type SlugProps = {
  layoutData: any;
  page: any;
  slug: string;
  currentUrl: string;
  defaultHasAuthenticated: boolean;
  preview: boolean;
};

const Slug = ({
  page,
  slug,
  currentUrl,
  layoutData,
  defaultHasAuthenticated,
  preview,
}: SlugProps) => {
  const router = useRouter();

  const { hasAuthenticated } = useAuth(defaultHasAuthenticated);
  const needsAuthentication = PATHS_WITH_AUTH.some((path) => router.asPath.startsWith(path));
  const showLogin =
    needsAuthentication &&
    !hasAuthenticated &&
    !preview &&
    process.env.NEXT_PUBLIC_DISABLE_LOGIN !== "true";

  const pageType = page.slug.content.__typename.toLowerCase();
  const pageData = page.slug.data;
  const pageSeo = page.slug.seo;

  const leftMenuItems = layoutData.siteHeaderData.menuItems;

  const paymentDaysContextValue: PaymentDaysContextValue = {
    paymentDays: layoutData.paymentDays,
    informationBlock: layoutData.paymentDaysInformationBlock,
    informationBlockPlacement: layoutData.paymentDaysInformationBlockPlacement,
  };

  return (
    <Layout {...layoutData}>
      <Meta
        title={pageSeo?.title}
        description={pageSeo?.description}
        image={metaImageUrl(pageSeo)}
        imageAlt={
          pageSeo?.ivSocialMediaImage?.[0]?.Metadata?.[0]?.Value ||
          socialMediaDefaultImage?.imageAltText
        }
        keywords={pageSeo?.keywords?.map((i: string) => i)}
        canonicalUrl={pageSeo?.canonicalUrl}
        url={getBaseUrl() + currentUrl}
        path={currentUrl}
        linkedFaqs={pageSeo?.linkedFaqs}
        noIndex={pageSeo?.noIndex}
        noFollow={pageSeo?.noFollow}
      />
      {showLogin ? (
        <BrandPortalLogin page={page} />
      ) : (
        <PaymentDaysContext.Provider value={paymentDaysContextValue}>
          <Page pageData={pageData} pageType={pageType} slug={slug} leftMenuItems={leftMenuItems} />
        </PaymentDaysContext.Provider>
      )}
    </Layout>
  );
};

export default Slug;

export const getStaticPaths: GetStaticPaths = async () => {
  const paths = [...(await getUrlStructureItems(false)), ...(await getFaqUrlStructure(false))]
    .filter(
      (item) =>
        item.slug !== "sida-hittades-inte" &&
        item.slug !== "nagonting-gick-fel" &&
        item.url.startsWith("/") &&
        !item.url.startsWith("/testsidor") &&
        !item.url.startsWith("/intressant-vetande") &&
        !item.url.startsWith("/ingen-missad-ersattning") &&
        !item.url.startsWith("/om-oss/nyhetsbrev/mina-prenumerationer") &&
        !item.url.startsWith(
          "/varumarke/digital-tillganglighet/kriterier-for-digital-tillganglighet"
        ) &&
        !item.url.startsWith("/varumarke/digital-tillganglighet/anvandares-olika-behov")
    )
    .map((i) => i.url);
  return { paths, fallback: "blocking" };
};

export const getStaticProps: GetStaticProps = async (context) => {
  const info = getStaticPropsInfo(context, "", "start");
  trackEvent(Event.GET_STATIC_PROPS, { path: info.path, preview: info.preview });

  const [urlStructure, siteTreeStructure] = await Promise.all([
    UrlStructureService(info.preview),
    GetSiteTreeStructure(info.preview),
  ]);

  const page = pathHasContent(urlStructure, info.path, info.preview)
    ? info.path.startsWith("/kundtjanst/fragor-och-svar/")
      ? await fetchFaqPageBySlug(urlStructure, info.slug, info.pageNumber, info.preview)
      : await fetchPageBySlug(urlStructure, info.slug, info.pageNumber, info.preview)
    : 404;

  if (page === 404) {
    return {
      notFound: true,
      revalidate: 120,
    };
  }

  const layoutData = await getLayoutData(
    urlStructure,
    siteTreeStructure,
    info.path,
    info.preview,
    page?.slug?.hideContactsectionInFooter
  );

  const insuranceName = getInsuranceName(page?.slug?.data);
  const insuranceCoveredTargetGroups = getInsuranceCoveredTargetGroups(page?.slug?.data);
  layoutData.insuranceName = insuranceName;
  layoutData.insuranceCoveredTargetGroups = insuranceCoveredTargetGroups;

  return {
    props: {
      layoutData,
      page: page,
      currentUrl: info.path,
      slug: info.slug,
      defaultHasAuthenticated: false,
      preview: info.preview,
    },
    revalidate: 60,
  };
};
